.paymentSuccess {
  color: rgba(0, 0, 0, 0.5);
  background-color: lightgreen;
  /* pointer-events: none; */
}

.paymentFailed {
  color: rgba(0, 0, 0, 0.5);
  background-color: red;
  /* pointer-events: none; */
}
.paymentRefunded {
  color: rgba(0, 0, 0, 0.5);
  background-color: #E4A11B;
  /* pointer-events: none; */
}