.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
}

.custom-upload-button {
  background-color: #2196f3;
  color: #fff;
  padding: 10px 20px;
  margin: 20px 20px;
  display: block;
  width: 180px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.custom-upload-button:hover {
  background-color: #1976d2;
}
.disabledRow {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.12);
  pointer-events: none;
}
.updateAvailable {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(6, 247, 46, 0.12);
  /* pointer-events: none; */
}