.messageContent {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin: 10px;
}

.customerMessageContent {
  align-items: flex-start;
}

.messageHolder {
  display: flex;
  justify-content: end;
  align-items: start;
}

.customerMessageHolder {
  justify-content: start;
}

.textMessageBubble {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  padding: 14px;
  border-radius: 12px;
  max-width: 80%;
}

.messageTime {
  margin-right: 12px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  white-space: nowrap;
  font-size: 13px;
  margin-top: 6px;
}

.dockMessageIcon {
  margin-left: 8px;
  width: 40px;
  border-radius: 20px;
}

.customerMessageIcon {
  margin-right: 8px;
  width: 40px;
  border-radius: 20px;
  background-color: #1A73E8;
  color: white;
  justify-content: center;
  display: flex;
  height: 40px;
  align-items: center;
}

.suggestionsContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 58px;
}

.suggestionChip {
  padding: 10px;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 14px;
  background-color: #ebebeb;
  margin-right: 10px;
}

.suggestionChipsHolder {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.formBody {
  padding: 20px;
  border: 1px solid black;
  border-radius: 14px;
  text-align: center;
}

.formTitleSeprator {
  margin: 15px 10px;
}

.formFields {
  display: flex;
  flex-direction: column;
}

.formTextField {
  margin-bottom: 15px;
}

.submitButton {
  background: black;
  color: white;
  border-radius: 5px;
  padding: 6px 0px;
}

.cardHolder {
  padding: 20px;
  border: 1px solid black;
  border-radius: 14px;
  text-align: center;
}

.cardImage {
  width: 400px;
  height: 200px;
}

.mb-10 {
  margin-bottom: 10px;
}

.w-250 {
  width: 250px;
}
